<template>
  <v-container v-if="loaded">
    <v-card v-if="!regId">
      <v-card-title>M. Ed. Registration</v-card-title>
      <v-card-text>There was no record found for you in the M.Ed. registration system. Please contact Lindsey Fain (<a href="mailto:lindsey.fain@covenant.edu">lindsey.fain@covenant.edu</a>) to correct this data problem and access the registration page.</v-card-text>
    </v-card>
    <v-card v-else-if="regSubmitted">
      <v-card-title>M.Ed. Registration Submitted</v-card-title>
      <v-card-text v-html="confirmationText"></v-card-text>
      <pricing :floating="true" :course-count="courseCount" :graduating="graduating" :housing-weeks="housingWeeks" :housing-choice="housingChoice" :suite-choice="suiteChoice"></pricing>
    </v-card>
    <v-card v-else style="margin-bottom:calc(10% + 20px)">
      <v-card-title>M. Ed. Registration</v-card-title>
      <v-stepper v-model="step" ref="stepperRef" class="formSectionStepperVertical" vertical>
        <v-stepper-step :complete="step > 1" step="1">{{ baseInfoTitle }}</v-stepper-step>
        <v-stepper-content :step="1" ref="sectionRef">
          <form-section ref="baseInfoForm" :inputs="baseInfoInputs" :form-data="formData" @update="update"></form-section>
          <v-toolbar flat style="padding-left:0">
            <v-btn class="no-print" outlined @click="next">Next</v-btn>
          </v-toolbar>
        </v-stepper-content>
        <v-stepper-step :complete="step > 2" step="2">{{ contactTitle }}</v-stepper-step>
        <v-stepper-content :step="2" ref="sectionRef">
          <form-section ref="contactInfoForm" :inputs="contactInputs" :form-data="formData" @update="update"></form-section>
          <v-toolbar flat style="padding-left:0">
            <v-btn class="no-print" outlined style="margin-right:1em" @click="back">Back</v-btn>
            <v-btn class="no-print" outlined @click="next">Next</v-btn>
          </v-toolbar>
        </v-stepper-content>
        <v-stepper-step :complete="step > 3" step="3">Registration</v-stepper-step>
        <v-stepper-content :step="3" ref="sectionRef">
          <reg-form ref="regForm" :classification="classification" :specialization="specialization" @update="update"></reg-form>
          <v-toolbar flat style="padding-left:0">
            <v-btn class="no-print" outlined style="margin-right:1em" @click="back">Back</v-btn>
            <v-btn class="no-print" outlined @click="next">Next</v-btn>
          </v-toolbar>
        </v-stepper-content>
        <v-stepper-step :complete="step > 4" step="4">{{ housingTitle }}</v-stepper-step>
        <v-stepper-content :step="4" ref="sectionRef">
          <form-section ref="housingForm" :inputs="housingInputs" :form-data="formData" @update="update"></form-section>
          <v-toolbar flat style="padding-left:0">
            <v-btn class="no-print" outlined style="margin-right:1em" @click="back">Back</v-btn>
            <v-btn class="no-print" outlined @click="next">Next</v-btn>
          </v-toolbar>
        </v-stepper-content>
        <v-stepper-step :complete="step > 5" step="5">{{ ferpaTitle }}</v-stepper-step>
        <v-stepper-content :step="5" ref="sectionRef">
          <form-section ref="ferpaForm" :inputs="ferpaInputs" :form-data="formData" @update="update"></form-section>
          <v-toolbar flat style="padding-left:0">
            <v-btn class="no-print" outlined style="margin-right:1em" @click="back">Back</v-btn>
            <v-btn color="primary" class="no-print" outlined @click="submit">Submit</v-btn>
          </v-toolbar>
        </v-stepper-content>
      </v-stepper>
      <pricing v-if="pricingActivated" :course-count="courseCount" :online-course="'fallOnline' in formData && formData.fallOnline !== '' && formData.fallOnline !== 'No Selected Course'" :capstone-course="'capstone' in formData && formData.capstone !== '' && formData.capstone !== 'No Selected Course'" :graduating="graduating" :housing-weeks="housingWeeks" :housing-choice="housingChoice" :suite-choice="suiteChoice"></pricing>
    </v-card>
  </v-container>
</template>
<style>
div.formSectionStepperVertical div.v-stepper__wrapper {
  padding-left: 10px;
}
div.formSectionStepperVertical div.v-stepper__step--complete i.fas {
  font-size: 1.3em;
}
div.formSectionStepperVertical div.v-stepper__wrapper form.v-form {
  margin-top: 4px;
}
div.formSectionStepperVertical div.v-stepper__wrapper .v-toolbar div.v-toolbar__content {
  padding-left: 0;
}
</style>
<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
export default {
  components: {
    FormSection: () => import('@/components/forms/sections/Section'),
    RegForm: () => import('@/components/med/reg-form'),
    Pricing: () => import('@/components/med/pricing')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const step = ref(1)
    const loaded = ref(false)
    const regId = ref(false)
    const regSubmitted = ref(false)
    const depositPaid = ref(false)
    const confirmationText = ref('')

    const formId = ref('61ddcabd90f59d31f887e13d')
    const revisionId = ref('')
    const baseInfoTitle = ref('Your Information')
    const baseInfoInputs = ref([])
    const contactTitle = ref('Contact Info')
    const contactInputs = ref([])
    const regTitle = ref('Course Registration')
    const regInputs = ref([])
    const housingTitle = ref('Housing')
    const housingInputs = ref([])
    const ferpaTitle = ref('Privacy Release')
    const ferpaInputs = ref([])
    const dataMap = {
      legalName: 'Full Legal Name',
      bannerId: 'Banner ID',
      specialization: 'Specialization',
      classification: 'Classification',
      school: 'School Name',
      schoolAddress: 'School Address',
      address: 'Street Address',
      city: 'City',
      state: 'State',
      zip: 'Zip',
      nation: 'Nation',
      home: 'Home Phone',
      cell: 'Cell Phone',
      work: 'Business Phone',
      email: 'Primary Email',
      housingWeeks: 'When is housing needed?',
      housingPref: 'Desired suite arrangement',
      ferpa: 'FERPA Release',
      releaseAcad: 'Academic Information',
      releaseFin: 'Financial Information'
    }
    const formData = reactive({})
    const specialization = ref('')
    const classification = ref('')

    onMounted(async () => {
      const data = await root.$feathers.service('forms/base').get(formId.value)
      revisionId.value = data.revisions.active
      const revision = await root.$feathers.service('forms/revision').get(data.revisions.active)
      confirmationText.value = revision.confText.replace(/<a /ig, '<a target="_blank" ')
      baseInfoTitle.value = revision.sections[0].title || 'Your Information'
      baseInfoInputs.value = revision.sections[0].inputs || []
      contactTitle.value = revision.sections[1].title || 'Contact Info'
      contactInputs.value = revision.sections[1].inputs || []
      regTitle.value = revision.sections[2].title || 'Course Registration'
      housingTitle.value = revision.sections[3].title || 'Housing'
      housingInputs.value = revision.sections[3].inputs || []
      ferpaTitle.value = revision.sections[4].title || 'Privacy Release'
      ferpaInputs.value = revision.sections[4].inputs || []

      const { data: regData } = await root.$feathers.service('med/registration').find({ query: { pidm: user.value.pidm, $sort: { term: -1 } } })
      if (regData.length > 0) {
        regId.value = regData[0]._id
        if ('regStatus' in regData[0] && (regData[0].regStatus === 'pending' || regData[0].regStatus === 'processed')) {
          regSubmitted.value = true
        }
        if ('submitted' in regData[0] && 'legalName' in regData[0].submitted) {
          for (const field in regData[0].submitted) {
            if (field in dataMap) {
              const label = dataMap[field]
              formData[label] = regData[0].submitted[field]
              confirmationText.value.replace(new RegExp('<code>' + label + '</code>', 'g'), regData[0].submitted[field])
              if (field === 'housingWeeks') {
                switch (regData[0].submitted.housingWeeks) {
                  case 'All Weeks':
                    housingWeeks.value = 3
                    break
                  case 'Weeks 1+2':
                  case 'Weeks 1+3':
                  case 'Weeks 2+3':
                    housingWeeks.value = 2
                    break
                  case 'Week 1':
                  case 'Week 2':
                  case 'Week 3':
                    housingWeeks.value = 1
                    break
                }
              } else if (field === 'housingPref') {
                housingChoice.value = regData[0].submitted.housingPref
                if (housingChoice.value === '3-person suite') {
                  if (regData[0].submitted.room1.length === 1) {
                    suiteChoice.value = '1-person'
                  } else {
                    suiteChoice.value = '2-person'
                  }
                }
              }
            } else {
              update({ field, value: regData[0].submitted[field] })
            }
          }
        } else {
          for (const field in regData[0]) {
            if (field in dataMap) {
              if (field === 'classification') {
                switch (regData[0][field]) {
                  case 1:
                    formData[dataMap[field]] = '1st Year Student'
                    break
                  case 2:
                    formData[dataMap[field]] = '2nd Year Student'
                    break
                  case 3:
                    formData[dataMap[field]] = '3rd Year Student'
                    break
                }
              } else if (field in regData[0]) {
                formData[dataMap[field]] = regData[0][field]
              }
            }
          }
        }
      }
      loaded.value = true
    })

    function update ({ field, value, index, action }) {
      if (field === 'Specialization') specialization.value = value
      else if (field === 'Classification') classification.value = value
      if (value == null || typeof (value) === 'undefined') {
        if (field in formData) delete formData[field]
      } else if (index != null && field === 'E-bill') {
        if (action === 'remove') {
          formData[field].splice(index, 1)
        } else {
          if (!Array.isArray(formData[field])) formData[field] = []
          while (!(index in formData[field])) formData[field].push({})
          formData[field][index] = value
        }
      } else {
        formData[field] = value
      }
      if (field === 'When is housing needed?') {
        switch (value) {
          case 'All Weeks':
            housingWeeks.value = 3
            break
          case 'Weeks 1+2':
          case 'Weeks 1+3':
          case 'Weeks 2+3':
            housingWeeks.value = 2
            break
          case 'Week 1':
          case 'Week 2':
          case 'Week 3':
            housingWeeks.value = 1
            break
        }
      } else if (field === 'Desired suite arrangement') {
        housingChoice.value = value
      } else if (field === '2-person suite') {
        if ('3-person suite' in formData) delete formData['3-Person suite']
        if ('4-person suite' in formData) delete formData['4-Person suite']
      } else if (field === '3-person suite') {
        if ('Which room do you want to be in?' in value) {
          suiteChoice.value = value['Which room do you want to be in?']
        }
        if ('2-person suite' in formData) delete formData['2-Person suite']
        if ('4-person suite' in formData) delete formData['4-Person suite']
      } else if (field === '4-person suite') {
        if ('2-person suite' in formData) delete formData['2-Person suite']
        if ('3-person suite' in formData) delete formData['3-Person suite']
      } else if (field === 'graduating') {
        graduating.value = value.substring(0, 1) === 'Y'
      }
      let courses = 0
      if ('weekOne' in formData && formData['weekOne'] !== '' && formData['weekOne'] !== 'No Selected Course') courses++
      if ('weekTwo' in formData && formData['weekTwo'] !== '' && formData['weekTwo'] !== 'No Selected Course') courses++
      if ('weekThree' in formData && formData['weekThree'] !== '' && formData['weekThree'] !== 'No Selected Course') courses++
      if ('fallOnline' in formData && formData['fallOnline'] !== '' && formData['fallOnline'] !== 'No Selected Course') courses++
      if ('capstone' in formData && formData['capstone'] !== '' && formData['capstone'] !== 'No Selected Course') courses++
      courseCount.value = courses
      root.$store.commit('updateActivity')
    }

    const baseInfoForm = ref(null)
    const contactInfoForm = ref(null)
    const regForm = ref(null)
    const housingForm = ref(null)
    const ferpaForm = ref(null)
    const pricingActivated = ref(false)
    function next () {
      switch (step.value) {
        case 1:
          if (baseInfoForm.value.validate()) step.value++
          break
        case 2:
          if (contactInfoForm.value.validate()) step.value++
          break
        case 3:
          if (regForm.value.validate()) step.value++
          break
        case 4:
          if (housingForm.value.validate()) step.value++
          break
      }
      if (!pricingActivated.value && step.value >= 3) pricingActivated.value = true
      root.$store.commit('updateActivity')
    }
    function back () {
      if (step.value > 1) step.value--
    }
    async function submit () {
      if (ferpaForm.value.validate()) {
        let dataArr = []
        for (const field in formData) {
          if (Array.isArray(formData[field]) && formData[field].length > 0 && typeof (formData[field][0]) === 'object') {
            // Repeatable block; convert the sub-objects into arrays for storage in the database
            let value = []
            for (let i = 0; i < formData[field].length; i++) {
              let temp = []
              for (const l in formData[field][i]) {
                temp.push({ field: l, value: formData[field][i][l] })
              }
              value.push(temp)
            }
            dataArr.push({ field, value })
          } else {
            dataArr.push({ field, value: formData[field] })
          }
        }

        const submittedDate = new Date()
        const obj = {
          user: user.value._id,
          name: user.value.name,
          form: {
            name: 'M.Ed. Registration Form',
            base: formId.value,
            revision: revisionId.value
          },
          status: 'Submitted',
          submittedDate,
          dataArr
        }
        try {
          await root.$feathers.service('forms/submission').create(obj)
        } catch (e) {
          root.$store.dispatch('main/snackbar', { active: true, type: 'error', timeout: 8000, text: 'Error saving confirmation data: ' + e })
        }

        // Convert the formData into an object to match the registration submitted
        const submitted = {}
        for (const field in dataMap) {
          const label = dataMap[field]
          if (label in formData) submitted[field] = formData[label]
        }
        const regFields = ['graduating', 'weekOne', 'weekTwo', 'weekThree', 'fallOnline', 'capstone']
        for (const field of regFields) {
          if (field in formData && formData[field] != null && formData[field] !== '' && formData[field] !== 'No Selected Course') {
            submitted[field] = formData[field]
          }
        }
        // Housing Roommates
        if (submitted.housingWeeks !== 'None' && submitted.housingPref in formData) {
          const block = formData[submitted.housingPref]
          switch (submitted.housingPref) {
            case '2-person suite':
              submitted.room1 = [ user.value.name ]
              submitted.room2 = [ block['Suitemate (Optional)'] ]
              break
            case '3-person suite':
              if ('Which room do you want to be in?' in block) {
                if (block['Which room do you want to be in?'] === '1-person') {
                  submitted.room1 = [ user.value.name ]
                  submitted.room2 = [ block['Suitemate 1 (Optional)'], block['Suitemate 2 (Optional)'] ]
                } else if (block['Which room do you want to be in?'] === '2-person') {
                  submitted.room1 = [ user.value.name, block['Roommate (Optional)'] ]
                  submitted.room2 = [ block['Suitemate (Optional)'] ]
                }
              }
              break
            case '4-person suite':
              submitted.room1 = [ user.value.name, block['Roommate (Optional)'] ]
              submitted.room2 = [ block['Suitemate 1 (Optional)'], block['Suitemate 2 (Optional)'] ]
              break
          }
        }
        // Ebills
        if ('E-bill' in formData && formData['E-bill'].length > 0) {
          submitted.ebill = []
          for (const { 'Recipient Name': name, 'Recipient Email': email } of formData['E-bill']) {
            submitted.ebill.push({ name, email })
          }
        }
        try {
          await root.$feathers.service('med/registration').patch(regId.value, { submitted, submitDate: new Date(), regStatus: 'pending', infoStatus: 'pending', housingStatus: 'pending' })
          // Send a confirmation email to the student
          await root.$feathers.service('system/email').create({
            from: 'report-email@covenant.edu',
            to: user.value.email,
            replyTo: 'lindsey.fain@covenant.edu',
            subject: 'M.Ed. Registration Submitted',
            html: 'Your M.Ed. Registration has been submitted successfully. The next step is to pay your deposit. You can visit your <a href="https://portal.covenant.edu/med/register">registration page</a> to view your confirmation with deposit details, Financial Aid forms, and more. If any of the information that you submitted needs to be updated, please reply to this email and let me know.<br/><br/>Lindsey Fain<br/>Associate Dean of Graduate School of Education<br/>Certification Official<br/>Covenant College',
            text: 'Your M.Ed. Registration has been submitted successfully. The next step is to pay your deposit. You can visit your registration page (https://portal.covenant.edu/med/register) to view your confirmation with deposit details, Financial Aid forms, and more. If any of the information that you submitted needs to be updated, please reply to this email and let me know.\n\nLindsey Fain\nAssociate Dean of Graduate School of Education\nCertification Official\nCovenant College'
          })
          // Send emails to Barbara Wingard and Linsey Fain that a student submitted their registration
          await root.$feathers.service('system/email').create({
            from: 'report-email@covenant.edu',
            to: ['lindsey.fain@covenant.edu', 'barbara.wingard@covenant.edu', 'jon.moon@covenant.edu'],
            subject: 'M.Ed. Registration Submitted',
            html: 'An M.Ed. Registration has been submitted for ' + user.value.name + '. Please go to the <a href="https://portal.covenant.edu/med/admin">M.Ed. Registration Admin page</a> to view and process this registration submission.'
          })
          regSubmitted.value = true
          // Scroll to top of page
          window.scrollTo(0, 0)
        } catch (e) {
          root.$store.dispatch('main/snackbar', { active: true, type: 'error', timeout: 8000, text: 'Error saving confirmation data: ' + e })
        }
      }
    }

    // Data needed for the Pricing popup
    const courseCount = ref(0)
    const graduating = ref(false)
    const housingWeeks = ref(0)
    const housingChoice = ref('')
    const suiteChoice = ref('')

    return {
      user,
      step,
      loaded,
      regId,
      regSubmitted,
      depositPaid,
      confirmationText,
      baseInfoTitle,
      baseInfoInputs,
      contactTitle,
      contactInputs,
      regTitle,
      regInputs,
      housingTitle,
      housingInputs,
      ferpaTitle,
      ferpaInputs,
      formData,
      classification,
      specialization,
      update,
      baseInfoForm,
      contactInfoForm,
      regForm,
      housingForm,
      ferpaForm,
      pricingActivated,
      next,
      back,
      submit,
      courseCount,
      graduating,
      housingWeeks,
      housingChoice,
      suiteChoice
    }
  }
}
</script>
